<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <translatable-editor
          v-model="form.content"
          label="Confirmation Text"
          class="mt-5 mb-5"
        />

        </div>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import {} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { translatable } from '@/assets/js/utils'

import TranslatableEditor from '@/components/form/TranslatableEditor.vue'
import TranslationLocales from '@/components/form/TranslationLocales.vue'
import { required } from '@core/utils/validation'

export default {
  components: { TranslatableEditor, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    // $table->json('confirmation_rule');
    // $table->json('content');
    // $table->json('cancellation_rule');
    // $table->json('cancellation_text');
    const initialForm = {
      content: translatable(props.resource, 'content'),
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,

      validate,
      required,
    }
  },
}
</script>
