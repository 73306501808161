import axios from '@axios'

// --- OrderRemark ---

const fetchOrderRemarks = queryParams => axios.get('/merchant/order-remarks', { params: queryParams })

const storeOrderRemark = data => axios.post('/merchant/order-remarks', data)

const updateOrderRemark = (id, data) => axios.put(`/merchant/order-remarks/${id}`, data)

const destroyOrderRemark = id => axios.delete(`/merchant/order-remarks/${id}`)

export {
  fetchOrderRemarks,
  storeOrderRemark,
  updateOrderRemark,
  destroyOrderRemark,
}
