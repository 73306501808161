<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <div>
          <h2 class="mt-5">
            confirmation_rule:
          </h2>

          <translatable-editor
            v-model="form.confirmation_text"
            label="Confirmation Text"
            class="mt-5 mb-5"
          />

          <v-select
            v-model="form.confirmation_rule.redeem_methods"
            label="Confirmation Methods"
            :items="redeemMethodOptions"
            item-text="title"
            item-value="value"
            outlined
            multiple
            :rules="[]"
          ></v-select>

          <v-switch
            v-model="form.confirmation_rule.instant"
            color="primary"
            :label="form.confirmation_rule.instant ? 'Instant Confirmation' : 'Manually Confirmed'"
            :messages="[ 'Confirm instantly after purchase' ]"
          ></v-switch>
        </div>

        <div>
          <h2 class="mt-5">
            cancellation_rule:
          </h2>

          <translatable-editor
            v-model="form.cancellation_text"
            label="Cancellation Policy"
            class="mt-5 mb-5"
          />

          <v-text-field
            v-model="form.cancellation_rule.cancellable_before_hour"
            label="Max Hours that customers can request cancellation before product is fulfilled"
          ></v-text-field>

          <v-text-field
            v-model="form.cancellation_rule.min_refund_day"
            label="Min Refund Processing Business Day"
          ></v-text-field>

          <v-text-field
            v-model="form.cancellation_rule.max_refund_day"
            label="Max Refund Processing Business Day"
          ></v-text-field>

          <v-switch
            v-model="form.cancellation_rule.cancellable"
            color="primary"
            :label="form.cancellation_rule.cancellable ? 'Can Request Cancellation' : 'Cannot Request Cancellation'"
            :messages="[ 'Customer can request order cancellation' ]"
          ></v-switch>
        </div>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import {} from '@mdi/js'
import { ref } from '@vue/composition-api'
import TranslatableEditor from '@/components/form/TranslatableEditor.vue'
import TranslationLocales from '@/components/form/TranslationLocales.vue'
import { required } from '@core/utils/validation'
import { translatable } from '@/assets/js/utils'

export default {
  components: { TranslatableEditor, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    // $table->json('confirmation_rule');
    // $table->json('confirmation_text');
    // $table->json('cancellation_rule');
    // $table->json('cancellation_text');
    const initialForm = {
      confirmation_text: translatable(props.resource, 'confirmation_text'),
      cancellation_text: translatable(props.resource, 'cancellation_text'),
      confirmation_rule: props.resource
        ? props.resource.confirmation_rule
        : {
            instant: true,
            redeem_methods: ['qr', 'print'],
          },
      cancellation_rule: props.resource
        ? props.resource.cancellation_rule
        : {
            cancellable: true,
            cancellable_before_hour: 48,
            min_refund_day: 10,
            max_refund_day: 14,
          },
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const redeemMethodOptions = [
      { title: 'QR code confirm', value: 'qr' },
      { title: 'Print out confirmation letter', value: 'print' },
    ]

    return {
      form,
      formElem,

      validate,
      required,

      redeemMethodOptions,
    }
  },
}
</script>
