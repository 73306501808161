<template>
  <div>
    <policy-aside
      v-if="aside === 'policy'"
      :active="true"
      :merchant="merchant"
      :resource="policyData"
      @changed="refresh"
      @aside="bol => { closeAside(bol); !bol && refresh() } "
    />

    <v-card class="mb-2">
      <v-card-title>
        <span>{{ merchant.name.en }}'s Policies</span>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click="toggleAside(null, 'policy')"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New Policy</span>
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="policies"
        hide-default-footer
      >
        <!-- id -->
        <template #[`item.id`]="{item}">
          <div class="d-flex align-center">
            {{ item.id }}
          </div>
        </template>

        <!-- cancellation_text -->
        <template #[`item.cancellation_text`]="{item}">
          <div
            v-html="t(item.cancellation_text)"
          >
          </div>
        </template>

        <!-- confirmation_text -->
        <template #[`item.confirmation_text`]="{item}">
          <div
            v-html="t(item.confirmation_text)"
          >
          </div>
        </template>

        <!-- Action -->
        <template #[`item.action`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="toggleAside(item, 'policy')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update Policy</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                link
                @click="deletePolicy(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiDeleteOutline, mdiDotsVertical, mdiPencil, mdiPencilOutline, mdiPlus } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'

import { destroyPolicy } from '@/api/merchant/policy'
import { notifyErrors } from '@/assets/js/utils'
import { t } from '@/plugins/i18n'
import PolicyAside from '../../policy-resource/PolicyAside.vue'

export default {
  components: { PolicyAside },

  props: {
    merchant: { type: Object, required: true },
    policies: { type: Array, required: true },
  },

  setup(props, { emit }) {
    const tableColumnHeaders = computed(() => [
      { text: 'ID', value: 'id', sortable: false },
      { text: 'CANCELLATION', value: 'cancellation_text', sortable: false },
      { text: 'CONFIRMATION', value: 'confirmation_text', sortable: false },
      { text: 'ACTION', value: 'action', sortable: false },
    ])

    const policyData = ref(null)
    const aside = ref('') // rule, slot, generator

    const closeAside = bol => {
      if (!bol) {
        policyData.value = null
        aside.value = null
      }
    }

    const toggleAside = slot => {
      policyData.value = slot
      aside.value = 'policy'
    }

    const ready = ref(false)

    const refresh = async () => {
      console.log('refresh!')
      ready.value = false
      emit('updated')
      closeAside()
      ready.value = true
    }

    watch(props.merchant, (newMerchant, oldMerchant) => {
      if (newMerchant.id !== oldMerchant.id) {
        console.log('merchant changed', newMerchant)
        refresh()
      }
    })

    const deletePolicy = policy => destroyPolicy(policy.id).then(refresh).catch(notifyErrors)

    return {
      tableColumnHeaders,
      policyData,
      aside,
      ready,
      refresh,
      closeAside,
      toggleAside,
      t,
      deletePolicy,
      icons: {
        mdiPencil,
        mdiPlus,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style scoped>
.cross-out {
  text-decoration: line-through;
  opacity: 0.5;
}
</style>
