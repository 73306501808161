<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col :cols="cols">
            <v-text-field
              v-model="form.shipping_fee"
              label="Shipping Fee"
              suffix="hkd"
              :rules="[between(form.shipping_fee, 0, 999)]"
            ></v-text-field>

            <v-text-field
              v-model="form.waived_at_subtotal"
              label="Free Shipping if min order total is"
              suffix="hkd"
              :rules="[between(form.waived_at_subtotal, 0, 99999)]"
            ></v-text-field>

            <v-divider class="mb-5" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import {} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { between } from '@core/utils/validation'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      shipping_fee: props.resource ? props.resource.shipping_fee : null,
      waived_at_subtotal: props.resource ? props.resource.waived_at_subtotal : null,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      between,
    }
  },
}
</script>
