<template>
  <div id="merchant-view">
    <merchant-aside
      v-if="aside"
      v-model="aside"
      :resource="merchantData"
      @changed="reloadMerchant(); aside = false"
    ></merchant-aside>

    <v-row>
      <v-col
        cols="12"
      >
        <a @click="$router.back()">Back</a>

        <v-tabs
          v-model="currentTab"
          show-arrows
          class="user-tabs mt-2"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            :disabled="tab.disabled"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-if="merchantData"
          id="user-tabs-content"
          v-model="currentTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <merchant-overview
              :merchant="merchantData"
              @updated="reloadMerchant"
              @edit="aside = true"
            />
          </v-tab-item>
          <v-tab-item>
            <merchant-branch-list
              :merchant="merchantData"
              :branches="merchantData.branches"
              @updated="reloadMerchant"
            />
          </v-tab-item>
          <v-tab-item>
            <merchant-policy-list
              :merchant="merchantData"
              :policies="merchantData.policies"
              @updated="reloadMerchant"
            />
          </v-tab-item>
          <v-tab-item>
            <merchant-shipping-fee
              :merchant="merchantData"
              :policies="merchantData.policies"
              @updated="reloadMerchant"
            />
          </v-tab-item>
          <v-tab-item>
            <merchant-order-remark-list
              :merchant="merchantData"
              :order-remarks="merchantData.order_remarks"
              @updated="reloadMerchant"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import { mdiAlphaMBox, mdiAlphaPBox, mdiAlphaBBox, mdiAlphaFBox, mdiAlphaOBox } from '@mdi/js'
import router from '@/router'
import useMerchantView from '../useMerchant'
import MerchantAside from '../merchant-resource/MerchantAside.vue'
import MerchantOverview from './merchant-overview/MerchantOverview.vue'
import MerchantShippingFee from './merchant-overview/MerchantShippingFee.vue'
import MerchantBranchList from './merchant-overview/MerchantBranchList.vue'
import MerchantPolicyList from './merchant-overview/MerchantPolicyList.vue'
import MerchantOrderRemarkList from './merchant-overview/MerchantOrderRemarkList.vue'

export default {
  components: {
    MerchantAside,
    MerchantOverview,
    MerchantShippingFee,
    MerchantBranchList,
    MerchantPolicyList,
    MerchantOrderRemarkList,
  },

  setup() {
    const { loadMerchant, merchantData } = useMerchantView()

    const reloadMerchant = () => {
      merchantData.value = null

      return loadMerchant(router.currentRoute.params.id).then(() => store.dispatch('merchant/setCurrent', merchantData.value))
    }

    // UnRegister on leave

    const tabs = computed(() => {
      const options = [
        { icon: mdiAlphaMBox, title: 'OVERVIEW' },
        { icon: mdiAlphaBBox, title: 'BRANCH' },
        { icon: mdiAlphaPBox, title: 'POLICIES' },
        { icon: mdiAlphaFBox, title: 'SHIPPING FEE' },
        { icon: mdiAlphaOBox, title: 'ORDER REMARKS' },

        // { icon: mdiFileOutline, title: 'POLICIES' },
      ]

      return options
    })
    const currentTab = ref(0)

    const switchToDefaultTab = () => {
      const tab = router.currentRoute.query.tab || null
      const index = tabs.value.findIndex(t => t.title === tab)
      currentTab.value = Math.max(0, index)
    }

    onMounted(() => reloadMerchant().then(switchToDefaultTab))

    const aside = ref(false)

    return {
      tabs,
      merchantData,
      reloadMerchant,
      aside,

      currentTab,
    }
  },
}
</script>
