<template>
  <div>
    <div>{{ label }}</div>
    <!--
    <vue-editor
      v-for="locale in locales"
      v-show="formLocale === 'all' || formLocale === locale"
      :key="locale"
      :label="`${label} (${locale})`"
      outlined
      :value="value[locale]"
      @input="val => updateValue(val, locale)"
    ></vue-editor>
    -->

    <div
      v-for="locale in renderableLocales"
      v-show="formLocale === 'all' || formLocale === locale"
      :key="locale"
      class="editor"
    >
      <classic-editor
        :value="value[locale]"
        :options="eidtorOptions"
        @input="val => updateValue(val, locale)"
      />
    </div>
  </div>
</template>

<script>
import appStore from '@/store/app'
import { computed, ref } from '@vue/composition-api'
import ClassicEditor from './ClassicEditor.vue'

export default {
  components: {
    // VueEditor,
    ClassicEditor,
  },

  props: {
    locales: {
      type: Array,
      default() {
        return []
      },
    },
    label: {
      type: String,
      required: true,
    },
    eidtorOptions: {
      type: Object,
      default() {
        return {
          uploadUrl: '', // api upload post path, '' for generic or 'product'
          postUuid: '', // post uuid to attach this upload
        }
      },
    },
    value: {
      // { en, tc, sc ... }
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const curValue = ref(JSON.parse(JSON.stringify(props.value)))

    const formLocale = computed(() => appStore.state.formLocale)

    const updateValue = (value, locale) => {
      curValue.value[locale] = value
      console.log('cur value', curValue.value)
      console.log('new value', value)
      emit('input', curValue.value)
    }

    const { locales } = props
    const renderableLocales = computed(() => (locales.length ? locales : appStore.state.info.locales))

    return {
      renderableLocales,
      formLocale,
      updateValue,
    }
  },
}
</script>

<style lang="scss" scoped>
.editor + .editor {
  margin-top: 10px;
}
</style>
