<template>
  <v-navigation-drawer
    :value="active"
    temporary
    permanent
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 380 : '100%'"
    app
    @input="(val) => $emit('aside', val)"
  >
    <v-container>
      <a @click="$emit('aside', false)">Back</a>
    </v-container>

    <order-remark-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      @submit="handleSubmit"
    />
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { updateOrderRemark, storeOrderRemark } from '@api/merchant/order-remark'
import { ref } from '@vue/composition-api'
import { extractErrors, merchantId } from '@/assets/js/utils'
import OrderRemarkForm from './OrderRemarkForm.vue'

export default {
  components: { OrderRemarkForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true
      const data = { ...form, merchant_id: merchantId() }
      const request = props.resource ? updateOrderRemark(props.resource.id, data) : storeOrderRemark(data)
      await request
        .then(res => {
          emit(props.resource ? 'updated' : 'created', res.data.data)
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = extractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
