<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <div>
          <translatable-input
            v-model="form.name"
            label="Branch Name"
            class="mt-5 mb-5"
          />

          <translatable-input
            v-model="form.address"
            label="Branch Address"
            class="mt-5 mb-5"
          />

          <district-selector
            v-if="cities.length"
            v-model="form.district_id"
            :cities="cities"
          ></district-selector>

          <v-switch
            v-model="form.active"
            color="primary"
            :label="form.active ? 'Active' : 'Inactive'"
          ></v-switch>
        </div>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import {} from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import TranslatableInput from '@/components/form/TranslatableInput.vue'
import TranslationLocales from '@/components/form/TranslationLocales.vue'
import { required } from '@core/utils/validation'
import { translatable } from '@/assets/js/utils'
import { getCities } from '@/api/common'
import DistrictSelector from '@/components/form/DistrictSelector.vue'

export default {
  components: { TranslatableInput, TranslationLocales, DistrictSelector },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      name: translatable(props.resource, 'name'),
      address: translatable(props.resource, 'address'),
      district_id: props.resource ? props.resource.district_id : null,
      active: props.resource ? props.resource.active : 1,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)
    const cities = ref([])

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    onMounted(() => {
      getCities().then(res => {
        cities.value = res.data.data.records
        console.log('cities are', cities.value)
      })
    })

    return {
      form,
      formElem,

      validate,
      required,

      cities,
    }
  },
}
</script>
