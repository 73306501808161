<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col :cols="cols">
            <translation-locales class="mb-5" />

            <translatable-input
              v-model="form.name"
              label="Merchant Name"
            >
            </translatable-input>

            <translatable-editor
              v-model="form.introduction"
              label="Introduction of this merchant"
            >
            </translatable-editor>

            <v-text-field
              v-model="form.code"
              label="Code (e.g. MXC)"
              :rules="[required, between(form.code.length, 3, 9)]"
            ></v-text-field>

            <v-select
              v-model="form.time_zone_id"
              class="mt-2"
              label="Time zone"
              :items="timeZoneOptions"
              clearable
              item-text="title"
              item-value="value"
              outlined
            ></v-select>

            <v-date-picker v-model="form.joined_at" />

            <v-divider class="mt-2"></v-divider>

            <v-switch
              v-model="form.active"
              color="primary"
              :label="form.active ? 'Active' : 'Inactive'"
              :message="[ 'Active rule will be applied to generate slots automatically.' ]"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import {} from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'
import { between, required } from '@core/utils/validation'
import { translatable } from '@/assets/js/utils'
import TranslationLocales from '@/components/form/TranslationLocales.vue'
import TranslatableInput from '@/components/form/TranslatableInput.vue'
import TranslatableEditor from '@/components/form/TranslatableEditor.vue'
import appStore from '@/store/app'
import { date } from '@core/utils/filter'

export default {
  components: { TranslationLocales, TranslatableInput, TranslatableEditor },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      name: translatable(props.resource, 'name'),
      introduction: translatable(props.resource, 'introduction'),
      code: props.resource ? props.resource.code : '',
      time_zone_id: props.resource ? props.resource.time_zone_id : null,
      joined_at: props.resource ? props.resource.joined_at : '2022-01-01',
      country: 1,
      active: props.resource ? props.resource.active : true,
    }

    const timeZoneOptions = computed(() => appStore.state.timeZones.map(tz => ({ title: tz.name, value: tz.id })))

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    onMounted(() => {
      const tzName = Intl.DateTimeFormat().resolvedOptions().timeZone
      if (!form.value.time_zone_id) {
        const autoTz = appStore.state.timeZones.find(tz => tz.name === tzName) || false
        form.value.time_zone_id = autoTz.id
      }
      console.log('time zone to', tzName)
    })

    const validate = () => {
      if (formElem.value.validate()) {
        console.log('slot form', form.value)
        emit('submit', form.value)
      }
    }

    return {
      timeZoneOptions,
      form,
      formElem,
      validate,
      between,
      required,
    }
  },
}
</script>
