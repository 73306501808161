import { notifyErrors } from '@/assets/js/utils'
import { t } from '@/plugins/i18n'
import store from '@/store'
import {
  destroyMerchant, fetchMerchant
} from '@api/merchant/merchant'
import { ref } from '@vue/composition-api'

export default function useMerchantView() {
  const merchantData = ref(null)
  const loading = ref(false)

  // fetch data
  const loadMerchant = id => {
    merchantData.value = null
    loading.value = true

    return fetchMerchant(id)
      .then(res => {
        merchantData.value = res.data.data

        return res
      })
      .catch(notifyErrors)
      .finally(() => {
        loading.value = false
      })
  }

  const trashMerchant = id => destroyMerchant(id).catch(notifyErrors)

  const confirmTrashMerchant = merchant =>

    // console.log('merchant is', merchant)
    new Promise((resolve, reject) => {
      store.dispatch('app/addNotifications', [{
        title: 'Confirm Action',
        content: `Confirm Trash merchant: <b>${t(merchant.name)}</b> ?`,
        resolve: () => trashMerchant(merchant.id).then(resolve),
        resolveText: 'Confirm',
        reject: () => reject(),
        rejectText: 'No',
      }])
    })

  return {
    loading,

    merchantData,

    loadMerchant,
    trashMerchant,
    confirmTrashMerchant,

  }
}
