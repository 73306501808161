import axios from '@axios'

// --- Policy ---

const fetchPolicies = queryParams => axios.get('/merchant/policies', { params: queryParams })

const storePolicy = data => axios.post('/merchant/policies', data)

const updatePolicy = (id, data) => axios.put(`/merchant/policies/${id}`, data)

const destroyPolicy = id => axios.delete(`/merchant/policies/${id}`)

export {
  fetchPolicies,
  storePolicy,
  updatePolicy,
  destroyPolicy,
}
